import React, { Fragment, useEffect, useState } from "react";
import {Link} from "react-router-dom";
import workDetail from "../data/WorkDetail";
import "../styles/home/Work.css";
import Aos from "aos";
import "aos/dist/aos.css";

export default function Work (){
    const [data, setData] = useState(workDetail);

    useEffect(() => {
        Aos.init({ duration: 2000, once: true, easing: "ease" });
    }, []);

    return (
        <Fragment>
            <section className="work">
                <div className="overlay"></div>

                <div data-aos="fade-up" className="work-container">
                    <div className="work-heading">
                        <span>work flow</span>
                        <h2>What We Offer</h2>
                    </div>

                    <div className="column1">
                        <div className="work-row">
                                    <div className="work-card">
                                        <div className="work-column">
                                            <div className="content">
                                                <div className="number">
                                                    <Link to={"/properties"}><span>1</span></Link>
                                                </div>
                                                <div className="work-content">
                                                    <h3>BUY</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            <div className="work-card">
                                <div className="work-column">
                                    <div className="content">
                                        <div className="number">
                                            <Link to={"/properties"}><span>2</span></Link>                                        </div>
                                        <div className="work-content">
                                            <h3>RENT</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="work-card">
                                <div className="work-column">
                                    <div className="content">
                                        <div className="number">
                                            <Link to={"/contact"}><span>3</span></Link>                                        </div>
                                        <div className="work-content">
                                            <h3>SELL</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="work-card">
                                <div className="work-column">
                                    <div className="content">
                                        <div className="number">
                                            <Link to={"/contact"}><span>4</span></Link>                                        </div>
                                        <div className="work-content">
                                            <h3>NEW DEVELOPMENT</h3>
                                            <p>TBD</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};


