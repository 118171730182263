import "../styles/components/Dialog.css"


export default function Dialog({message, onDialog, onYes}) {


    return(
        <div className="popup-container" onClick={() => onDialog(false)}>
            <div className="popup-context" onClick={(e) => e.stopPropagation()}>
                <h3>{message}</h3>
                <div className="request-buttons">
                    <button onClick={() => onYes()} style={{background: '#9b8742', color: 'white'}}>Yes</button>
                    <button onClick={() => onDialog(false)} style={{background: 'red', color: 'white'}}>No</button>
                </div>
            </div>
        </div>
    )
}