import React from "react";
import "../styles/admin/AdminHeader.css";
import { Link } from "react-router-dom";
import useProtection from "../hooks/useProtection";
import { auth } from '../firebase/config'

function AdminHeader() {
    const user = useProtection().user;
    return (
        <div className="admin-header-container">
            <div className="current-user-email">
                <p>{user?.email}</p>
                <p>Welcome Back! <span>View your listings</span></p>
            </div>
            <div className="logout-container">
                <div className="logout-text" onClick={() => auth.signOut()}>
                    Logout
                </div>
                <Link to="/create-listing" style={{ color: "white" }}>
                    <div className="add-property-btn">Add Properties</div>
                </Link>
            </div>
        </div>
    );
}

export default AdminHeader;