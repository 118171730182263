import { useEffect, useState } from "react";

export default function OutsideClick(ref) {
    const [isClicked, setIsClicked] = useState(false);
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsClicked(true);
                setTimeout(() => {
                    setIsClicked(false);
                }, 10);
            } else {
                setIsClicked(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);
    return isClicked;
}