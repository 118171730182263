import AdminHeader from "./AdminHeader";
import { ToastTopCenter } from "../helper/ToastContainer.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import "../styles/admin/AdminOverview.css"
import { useCollection } from "../hooks/useCollection";
import PropertyCardAdmin from "./PropertyCardAdmin";


function AdminOverview() {
    const { properties, deleteData, updateData, searchTerm, filteredDataResult, searchItem } = useCollection();
    return (
        <>
            <AdminHeader />

            {ToastTopCenter}
            <div className="all-property-container">
                <div className="input-box">
                    <input
                        type='text'
                        value={searchTerm}
                        onChange={e => searchItem(e.target.value)}
                        placeholder="Search property by title"
                    />
                    <FontAwesomeIcon icon={faSearch} style={{ color: "#9b8742" }} />
                </div>
                <div className="row-admin">
                <PropertyCardAdmin updateData={updateData} deleteData={deleteData} properties={searchTerm === "" ? properties : filteredDataResult} />
                </div>
            </div>
        </>
    )
}

export default AdminOverview;