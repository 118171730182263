import React, {useRef} from "react";
import "../styles/contact/ContactDetail.css";
import emailjs from '@emailjs/browser';
import {ToastContainer, toast} from "react-toastify";
import 'react-toastify/dist/ReactToastify.min.css';


const ContactDetail = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();


        emailjs.sendForm(
            'service_m8ab7di',
            'template_xkh3zd3',
            form.current,
            'fTl7x_5wj0j2T5z3H')
            .then(
                (result) => {
                    toast.success("Your message is sent!");
                    console.log(result.text);
                    console.log("message sent")

                }, (error) => {
                    console.log(error.text);
                });
    };

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <section className="contact-detail-section">

                <div className="form-container">
                    <div className="contact-form">
                        <div className="header-form">
                            <h2>
                                Got any questions? <br />{" "}
                                <span className="span-h2">
                Please don’t hesitate to contact us.{" "}
              </span>
                            </h2>
                        </div>
                        <div className="info-text-styling">
                        <p className="contact-styling" >
                            You need help buying, selling or renting your property or just gathering information for a future sale or investment opportunity; <br/>
                            You’re looking to understand and follow the evolution of the Real Estate market on  St Martin ? <br/> Our experienced team will be happy to assist in your query, Very promptly, just like you like it !!
                        </p>
                            <p className="contact-styling" >
                                Please call or email to schedule an appointment or just Call at your convenience; </p>

                            <p className="contact-styling" >
                                General inquiries	:	contact@anracaribbean.com <br/> <br/>
                                Offers              :	fadi@anracaribbean.com
                            </p>

                            <p className="contact-styling" >
                                <b>Tel		Dutch   :</b>  	<a>+1 721 5231055 </a>         Fadi WhatsApp <br/><br/>
                                <b>Tel     French	        :</b>      <a> + 33 7 85 770838</a>    Fadi
                                </p>


                        </div>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="formWord input-field">
                                <div className="label-wrapper">
                                    <label className="label-name">Name:</label>
                                    <input
                                        className="input-100 input-name"
                                        type="text"
                                        name="from_name"
                                        placeholder="Name..."
                                        required
                                    />
                                </div>
                                <div className="label-wrapper">
                                    <label className="label-name">Email:</label>
                                    <input
                                        className="input-100 input-email"
                                        type="email"
                                        name="from_email"
                                        placeholder="Email..."
                                        required
                                    />
                                </div>
                            </div>
                            <div className="formWord message-field">
              <textarea
                  className="input-100 input-message"
                  name="message"
                  required
                  placeholder="Please don't forget to describe your reason of contact..  "
              ></textarea>
                            </div>

                            <p className="contact-styling" >
                                <b>We speak: English 🇺🇸 , French 🇫🇷 Portuguese 🇵🇹 Spanish 🇪🇸 and Arabic 🇱🇧 </b>
                            </p>
                            <div className="btn-wrapper">
                                <button type="submit" value="Send" className="form-btn">
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactDetail;