import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const useProtection = () => {
    const user = useAuth();
    const history = useHistory();
    useEffect(() => {
        if (!user) history.replace('/login');
    }, [user])

    return { user, history }
}

export default useProtection;