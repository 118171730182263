import React from 'react';
import "bootstrap";

const Spinner = () => {
    return (
        <div className="spinner-border text-secondary" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    )
}

export default Spinner
