import Compressor from 'compressorjs';
import React, {useEffect, useState} from "react";
import "../styles/admin/CreateListingForm.css";
import { useCollection } from "../hooks/useCollection";
import {v4 as uuid} from 'uuid'
import {useParams,useHistory } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";
import {toast, ToastContainer} from "react-toastify";
import FileUploader from "../components/FileUploader";
import { Editor } from "react-draft-wysiwyg";
import { EditorState,convertFromHTML, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";

const convertHTML = (sampleMarkup) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup);
    const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
    );
    return state;
};

const CreateListingForm = () => {
    const history = useHistory()
    const [count, setCount] = useState(0);
    const [editorState, setEditorState] = useState(() =>
        EditorState.createEmpty()
    );
    const initialState = {
        listingType:"Buy",
        type:"Apartment",
        heading:"",
        description:"",
        price:"",
        address:"",
        location:"",
        surface:"",
        bedrooms:0,
        bath:0,
        images: [],
        imgUrls:[],
    }
    const [editMode,setEditMode] = useState(false)
    const [formData, setFormData] = useState(initialState);
    const {id} = useParams()
    const getMatchingIdData = async () => {
        const docRef = doc(db, 'properties', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setFormData({...docSnap.data(),images:docSnap.data().images ?? docSnap.data().imgUrls});
            setEditorState(EditorState.createWithContent(convertHTML(docSnap.data()?.description || "")))
            console.log("Document data:", docSnap.data());
        } else {
            toast("No such document found")
            console.log("No such document!");
        }
    };

    useEffect(()=>{
        if(!id) return
        setEditMode(true)
        getMatchingIdData()

    },[id])
    const {
        listingType,
        type,
        heading,
        description,
        price,
        address,
        location,
        images,
        surface,
        bedrooms,
        bath,
    } = formData;
    const {addData,uploadFiles,updateData} = useCollection()

    const [isUploading, setUploading] = useState(false)

    const formSubmit = (e)=>{
        e.preventDefault()
        editMode ? updateData(id,formData) :addData(formData,uuid())
        setFormData(initialState)
        history.push('/admin')
    }


    const handleChange = (e) => {
        const {name,value} = e.target
        setFormData(initial=>{
            return {
                ...initial,
                [name]:value
            }
        });
    };

    const handleEditorChange = (editorState) => {
        setEditorState(editorState)
        setFormData(initial=>{
            return {
                ...initial,
                description : convertToHTML({})(editorState.getCurrentContent())
            }
        });
    }

    const onUploadChange = async(files) => {
        setUploading(true)

        const compressedFiles = await Promise.all(
            files.map((file) => {
                return new Promise((resolve) => {
                    new Compressor(file, {
                        quality: 0.4,
                        maxWidth: 1024,
                        maxHeight: 1024,
                        success: (result) => {
                            resolve(result);
                        },
                    });
                });
            })
        );

        const uploadedFiles  = await uploadFiles(Array.from(compressedFiles));
        setUploading(false)
        setFormData(f=>{
            return {
                ...f,
                images:[...f.images, ...uploadedFiles],
                imgUrls:[...f.images, ...uploadedFiles]
            }
        })
    }

    const handleCloseBtn = () => {
        history.push("/admin")
    }

    useEffect(() => {
        console.log(editorState);
    }, [editorState]);


    return(
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <div className="create-form-container">
                <h1 className="create-title">Create Property</h1>
                <button
                    style={{cursor:"pointer"}}
                    className="close-bttn"
                    onClick={handleCloseBtn}
                >Go back</button>
                <form onSubmit={formSubmit}>
                    <div className="property-info-wrapper">
                        <h4>Property Information</h4>
                        <hr className="create-form-hr"/>


                        <div className="wrapper-1">
                            <label htmlFor="type">Property Type</label>
                            <select
                                value={type}
                                name='type'
                                className="form-container"
                                onChange={handleChange}
                                required
                            >
                                <option value='apartment'>Apartment</option>
                                <option value='villa'>Villa</option>
                                <option value='studio'>Studio</option>
                                <option value='condominium'>Condominium</option>
                                <option value='land'>Land</option>
                            </select>

                            <label htmlFor="listingType">Listing Type</label>
                            <select
                                value={listingType}
                                name="listingType"
                                className="form-container"
                                onChange={handleChange}
                                required
                            >
                                <option value='buy'>Buy</option>
                                <option value='rent'>Rent</option>
                            </select>
                            <div>
                                <label htmlFor="heading">Property Title</label>
                                <input
                                    placeholder="Property title..."
                                    className="form-container"
                                    type='text'
                                    name='heading'
                                    value={heading}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                        </div>

                        <div className="wrapper-2">
                            <label htmlFor="bedrooms">Bedrooms</label>
                            <input
                                on={() => setCount(count + 1)}
                                className='form-container'
                                type='number'
                                name='bedrooms'
                                value={bedrooms}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="wrapper-2">
                            <label htmlFor="baths">Baths</label>
                            <input
                                onChange={() => setCount(count + 1)}
                                className="form-container"
                                type='number'
                                name='bath'
                                value={bath}
                                onChange={handleChange}
                                required
                            />
                        </div>


                        <div className="wrapper-3">
                            <label htmlFor="surface">Surface</label>
                            <input
                                className='form-container'
                                type='text'
                                name='surface'
                                value={surface}
                                onChange={handleChange}
                                required
                            />
                        </div>
                            <div className="wrapper-3">
                            <label htmlFor="price">Price</label>
                            <input
                                className="form-container"
                                type='text'
                                name='price'
                                value={price}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="location-info">
                        <h4>Location Information</h4>
                        <hr/>

                        <div className="wrapper-4">
                            <label htmlFor="address">Address</label>
                            <input
                                className="form-container"
                                type="text"
                                name="address"
                                value={address}
                                onChange={handleChange}
                                required
                            />
                        </div>
                            <div className="wrapper-4">
                            <label htmlFor='location'>Location</label>
                            <input
                                className="form-container"
                                type="text"
                                name="location"
                                value={location}
                                onChange={handleChange}
                                required
                            />
                        </div>

                    </div>
                    <hr/>
                    <div className="image-description">
                        <div className="images-wrapper">
                            <label htmlFor='formFile'>Select images</label><br/>
                            <FileUploader
                                isUploading={isUploading}
                                onUploadChange={onUploadChange}
                                setFormData={setFormData}
                                formData={formData}
                            />
                            {isUploading && <span>Uploading...</span>}
                        </div>


                        <div className="description-wrapper">
                            <label htmlFor="description">Description:</label><br/>
                            <div className="editor-container" style={{
                                border: "1px solid black",
                                padding: '2px',
                                whiteSpace: 'wrap',
                                minHeight: '400px',
                                maxWidth: '500px'}}>
                                <Editor
                                    id="description"
                                    name="description"
                                    // value={description}
                                    placeholder="Tell something about the property..."
                                    editorState={editorState}
                                    onEditorStateChange={handleEditorChange}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                    <button className="form-btn" type="submit" disabled={isUploading}>
                        {isUploading ? 'Uploading...' : editMode ? 'Update': 'Add'}
                    </button>
                </form>
            </div>
            <div className="divider">

            </div>
        </>
    )
}

export default CreateListingForm;