import "../styles/components/PropertyFilter.css"

const filterList = ['All', 'Buy', 'Rent']

export default function PropertyFilter({ currentFilter, changeFilter }) {
    const handleClick = (newFilter) => {
        changeFilter(newFilter)
    }

    return (
        <div className="property-filter">
            <nav>
                <p className="filter-title">Filter by:</p>
                {filterList.map((f) => (
                    <button
                        key={f}
                        onClick={() => handleClick(f)}
                        className={currentFilter === f ? 'active' : ''}
                    >
                        {f}
                    </button>
                ))}
            </nav>
        </div>
    );
};
