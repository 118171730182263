import Navbar from "../components/Navbar";
import ContactDetail from "../contact/ContactDetail";
import React, { useEffect } from "react";
import Footer from "../components/Footer";

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <ContactDetail />
            <Footer />
        </>
    );
};
export default Contact;