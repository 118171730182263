import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
    apiKey: "AIzaSyD9m8iR25wap5fnKCAwtZ0_Ss6T5awvI60",
    authDomain: "anracaribbeanrealestate.firebaseapp.com",
    databaseURL: "https://anracaribbeanrealestate-default-rtdb.firebaseio.com",
    projectId: "anracaribbeanrealestate",
    storageBucket: "anracaribbeanrealestate.appspot.com",
    messagingSenderId: "737937294620",
    appId: "1:737937294620:web:81cf5d6e2288e536c5a6d9",
    measurementId: "G-L40KPYHNHQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore();
const storage = getStorage()


export { auth, db, storage };
