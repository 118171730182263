import React, { useEffect } from "react";
import Header from "../components/Header";
import OurVision from "../home/OurVision";
import Work from "../home/Work";
import Footer from "../components/Footer";
import PropertySlider from "../components/PropertySlider";


function Home(){
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div>

            <Header />
            <OurVision />
            <PropertySlider />
            <Work />
            <Footer />
        </div>
    )
}
export default Home;