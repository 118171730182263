import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "../styles/pages/Properties.css";
import { useCollection } from "../hooks/useCollection";
import PropertyFilter from "../components/PropertyFilter";
import {useState} from "react";
import Card from "../components/Card";


export default function PropertyList(){
    const {properties: data, searchItem, searchTerm, filteredDataResult} = useCollection()
    const [ currentFilter, setCurrentFilter ] = useState('All')

    const newProperties = searchTerm === "" ? data : filteredDataResult
    console.log(newProperties)

    const changeFilter = (newFilter) =>{
        setCurrentFilter(newFilter)
    }

    const properties = newProperties ? newProperties.filter((property) =>
        currentFilter === 'All' || property.listingType.toLowerCase() === currentFilter.toLowerCase()) : null

    const formSubmit = (e)=>{
        e.preventDefault()
    }

    return(
            <section className="property">
                <div className="center">
                    <h3>Properties</h3>
                </div>
                <hr className="property-hr" />

                <div className="filter-btns">
                    <form className="input-box" onSubmit={formSubmit}>
                        <input
                            type='text'
                            value={searchTerm}
                            onChange={(e)=>searchItem(e.target.value)}
                            placeholder="Search by title"
                        />
                        <FontAwesomeIcon icon={faSearch} style={{color: "#9b8742", marginLeft: "8px"}}/>
                    </form>

                    <PropertyFilter currentFilter={currentFilter} changeFilter={changeFilter}/>
                </div>


                <div className="row">

                <Card properties={properties}/>
                </div>
            </section>
    )
}