import Navbar from "../components/Navbar";
import AboutDetail from "../about/AboutDetail";
import { useEffect } from "react";
import Footer from "../components/Footer";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <AboutDetail />
            <Footer />
        </>
    );
};
export default About;