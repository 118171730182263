import React from "react";
import CreateListingForm from "./CreateListingForm";
import "../styles/admin/CreateListing.css"


const CreateListing = () => {


    return(
        <>
            <div className="grid-styling">
            <CreateListingForm />
        </div>
        </>
    )
}

export default CreateListing;