import React from "react";
import "../styles/components/Header.css";
import {Link} from "react-router-dom";
import Navbar from "./Navbar";
import HeaderSlider from "../home/HeaderSlider";


function Header(){
    return(
        <>
            <Navbar />
        <section className="header">
            <div className="container">
                <HeaderSlider />
                <div className="header-btns">
                    <div className="link link-buy">
                        <Link to="/properties" className="buy">
                            All Properties
                        </Link>
                    </div>
                </div>
            </div>
        </section>
        </>
    );
        };

        export default Header;
