import React from "react";
import { useState, useEffect } from "react";
import Input from "../components/Input";
import "../styles/auth/ForgotPassword.css";
import { ToastTopCenter } from "../helper/ToastContainer";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Logo from "../assets/logo/5.png"

const ForgotPassword = () => {
    const [inputValues, setInputValues] = useState({ email: "" });
    const { forgotPassword, currentUser } = useState("");
    const history = useHistory();

    useEffect(() => {
        if (currentUser) {
            history.push("/admin");
        }
    }, [currentUser]);

    const onChangeHandler = (e) => {
        const value = e.target.value;
        setInputValues({
            ...inputValues,
            [e.target.name]: value,
        });
    };

    const handleForgotPassword = () => {
        const { email } = inputValues;
        forgotPassword(email)
            .then((res) => {
                toast.info(
                    "We have sent a link to this email address please check to reset the password"
                );
            })
            .catch((err) => {
                toast.error(err.message);
            });
    };

    return (
        <>
            <div className="auth-container">
                {ToastTopCenter}
                <img className="logo" src={Logo} alt="logo anra caribbean"/>
                <p className="auth-container__text">
                    Typ your email address so that we can send you a link for you to
                    change your password.
                </p>
                <div className="auth-container__input-wrapper">
                    <div className="auth-container__input">
                        <Input
                            inputType="Email"
                            inputPlaceholder="Type admin email..."
                            lableText="Email"
                            inputName="email"
                            onInputChange={onChangeHandler}
                        />
                    </div>
                </div>

                <div className="auth-btn-group">
                    <button className="auth-btn" onClick={handleForgotPassword}>
                        {" "}
                        New Password
                    </button>
                </div>
            </div>
        </>
    );
};

export default ForgotPassword;