import "../styles/components/FileUploader.css";
import {useCollection} from "../hooks/useCollection";
import { useRef, useState} from 'react'


export default function FileUploader({onUploadChange,formData, setFormData, isUploading}) {
    const formRef = useRef()
    const {deleteData} = useCollection()
    const [images, setImages] = useState([]);

    const inputRef = useRef()

    const onChange = (event) => {
            const files = Array.from(event.target.files);
            readFiles(files).then((images) => {
                setImages(images);
            });
            onUploadChange(files)
    }

    function readFiles(files) {
        return Promise.all(
            files.map((file) => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            })
        );
    }

    const handleDelete = (id, index) => {
        deleteData(id);
        setFormData(f => {
            return {
                ...f,
                images: removeImageUrlAtIndex(f.images, index),
                imgUrls: removeImageUrlAtIndex(f.imgUrls, index)
            };
        });

        setImages(prev => removeImageUrlAtIndex(prev, index));
    };



    function removeImageUrlAtIndex(array, indexToRemove) {
        if (indexToRemove >= array.length) {
            // If index is out of range, do nothing and return the original array
            return array;
        } else {
            // Remove the URL at the specified index and return the new array
            return [...array.slice(0, indexToRemove), ...array.slice(indexToRemove + 1)];
        }
    }

    const imagesFiles = isUploading ? images : formData.images



    return (

        <div className="uploader-card">
            <form ref={formRef} className="upload-form dragover" action="/upload" method="post">
                <span className="inner"><span id="select" onClick={() => inputRef.current.click()} className="select"
                >Browse images here</span></span>
                <input
                    id="input"
                    ref={inputRef}
                    name="file"
                    type="file"
                    className="file:"
                    onChange={onChange}
                    accept=".jpg,.png,.jpeg,.mp4"
                    multiple
                    required
                />
            </form>
            <div className="upload-container">
                {imagesFiles?.map((src, index) => {
                    return <div key={index} className="imgs">
                        <img src={src} alt="image"/>
                        {!isUploading && <span onClick={() => handleDelete(src, index)}>&times;</span>}</div>
                })}
            </div>

        </div>
    )
}