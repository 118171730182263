import Slider from "react-slick";
import {useCollection} from "../hooks/useCollection";
import {Link} from "react-router-dom";
import SqftImage from "../assets/icons/sqft-img.png";
import BedroomImage from "../assets/icons/icons8-sleeping-in-bed-100.png";
import BathroomImage from "../assets/icons/icons8-shower-80.png";

//styles
import "../styles/home/PropertySlider.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PropertySlider() {
    const {properties:data} = useCollection()

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    dots: true,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            {data.length > 0 && (
                <div className="slider-wrapper">
                    <h2> Properties </h2>
                    <hr />
                    <Slider {...settings}>
                    {data.map(data => {
                return (
                    <Link to={`/properties/${data.id}`} key={data.id}>
                        <div className="column">
                            <div className="single-property">
                                <div className="card" key={data.id}>
                                    <div className="property-thumb">
                                        <div className="property-tag capitalize">{data.listingType}</div>
                                        <img
                                            src={data.imgUrls ? data.imgUrls[0] : ''}
                                            className="property-image"
                                            alt="Sint Maarten"
                                        />
                                        <span className="price"><b>{data.heading}</b></span>
                                    </div>
                                    <div className="property-content">
                                        <h4><b>{data.price}</b></h4>
                                        <div className="location-mark">
                                            <span className="location">{data.address.substring(0,30)}</span>
                                            <span className="location">{data.location}</span>
                                        </div>
                                    </div>
                                    <div className="property-footer">
                                        <ul>
                                            <li className="card-footer-list">
                                                <img
                                                    className="icon-image"
                                                    src={SqftImage}
                                                    alt="size"
                                                />
                                                <span>{data.surface}</span>
                                            </li>
                                            <li className="card-footer-list">
                                                <img
                                                    className="icon-image"
                                                    src={BedroomImage}
                                                    alt="bed"
                                                />
                                                <span>{data.bedrooms} Beds</span>
                                            </li>
                                            <li className="card-footer-list">
                                                <img
                                                    className="icon-image"
                                                    src={BathroomImage}
                                                    alt="bath"
                                                />
                                                <span>{data.bath} Baths</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                );
            })}
                    </Slider>
                </div>
            )}
        </>
    );
}

