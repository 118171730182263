import { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import useUnAuth from './useUnAuth';
import {auth} from '../firebase/config';

export const useLogin = () => {
    useUnAuth();
    const [{ error, isLoading }, setState] = useState({})
    const login = async (email, password) => {
        setState({ isLoading: true });
        signInWithEmailAndPassword(auth, email, password)
            .catch(err => setState({ error: err.message }))
    }


    return { error, isLoading, login }
}