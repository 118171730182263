import React from "react";
import {useLocation, useHistory} from "react-router-dom";
import {getAuth,signInWithPopup,GoogleAuthProvider} from 'firebase/auth'
import {doc,setDoc,getDoc,serverTimestamp} from 'firebase/firestore';
import {db} from '../firebase/config';
import {toast} from "react-toastify";
import {faGoogle } from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function OAuth (){
    const history = useHistory()
    const location = useLocation()

    const onGoogleAuthHandler = async () => {
        try {
            const auth =getAuth()
            const provider = new GoogleAuthProvider()
            const result = await signInWithPopup(auth,provider)
            const user = result.user
            const docRef = doc(db, 'users', user.uid)
            const docSnap = await getDoc(docRef)
            if (!docSnap.exists()){
                await setDoc(doc(db, 'users', user.uid),{
                    email:user.uid,
                    timestamp:serverTimestamp()
                })
            }
            history.push('/admin')
        } catch (error) {
            console.log(error)
            toast.error('Problem With Google Auth')
        }
    }

    return (
        <div>
            <h3 className="mt-2" style={{cursor:"pointer"}}>Sign {location.pathname === "/signin"} with &nbsp;
            <button style={{cursor:"pointer"}} onClick={onGoogleAuthHandler}>
                <FontAwesomeIcon icon={faGoogle} />
            </button>
            </h3>
        </div>
    )
}

