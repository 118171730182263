import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import "../styles/components/Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/logo/5.png";


const Footer = () => {
    return (
        <Fragment>
            <section className="footer">
                <div className="footer-container">
                    <div className="footer-row">
                        <div className="logo">
                            <Link to="/">
                                <img className="logo-img" src={Logo} />
                            </Link>
                        </div>
                        <div className="footer-column">
                            <div className="column-content">
                                <ul className="footer-link">
                                    <Link to="/contact" className="link-style">
                                        <li>Contact</li>
                                    </Link>
                                    <Link to="/about" className="link-style">
                                        <li>About</li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-column info-property">
                            <div className="column-content">
                                <ul>
                                    <div className="phone-nr">
                                    <li>
                    <span className="footer-icon">
                      <FontAwesomeIcon className="fa-phone" icon={faPhone} />
                    </span>
                                        <span className="text">Dutch: +1(721) 523 1055</span>
                                    </li>
                                    <li>
                    <span className="footer-icon">
                      <FontAwesomeIcon className="fa-phone" icon={faPhone} />
                    </span>
                                        <span className="text"> French: +33 7 85 770838</span>
                                    </li>
                                    </div>
                                    <br/>
                                    <div className="email-contact">
                                    <li>
                    <span className="footer-icon">
                      <FontAwesomeIcon
                          className="fa-envelope"
                          icon={faEnvelope}
                      />{" "}
                    </span>
                                        <span className="text"> contact@anracaribbean.com</span>
                                    </li>
                                    <li>
                    <span className="footer-icon">
                      <FontAwesomeIcon
                          className="fa-envelope"
                          icon={faEnvelope}
                      />{" "}
                    </span>
                                        <span className="text"> fadi@anracaribbean.com</span>
                                    </li>
                                    </div>
                                </ul>
                            </div>
                            <div className="footer-column">

                                <button className="btn-footer">
                                    <Link className="btn-link" to="/login">
                                        Login
                                    </Link>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="footer-2">
                    <div className="footer-2-container">
                        <p>Copyright @2022 All rights reserved AnRa Caribbean</p>
                    </div>
                </div>
            </section>
        </Fragment>
    );
};

export default Footer;
