import React, { useState, useEffect } from "react";
import "../styles/pages/PropertyDetail.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link, useParams, useLocation } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase/config";


const PropertyDetail = () => {

    const [singleProperty, setSingleProperty] = useState({});
    const {id} = useParams();
    const {pathname} = useLocation();

    useEffect(() => {
        getMatchingIdData();
    }, [id, pathname]);

    const getMatchingIdData = async () => {
        const docRef = doc(db, 'properties', id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setSingleProperty({...docSnap.data(), images: docSnap.data().images ?? docSnap.data().imgUrls});
            console.log("Document data:", docSnap.data());
        } else {
            console.log("No such document!");
        }
    };

    return (
        <>
            <div className="property-detail-container">
                <Carousel autoPlay={true} showArrows={true}>
                    {singleProperty.images &&
                        singleProperty.images.map((slideImage, index) => (
                            <div className="images-container" key={index}>
                                <img
                                    src={slideImage}
                                    alt="slideImages"
                                    width="200px"
                                    height="50px"/>
                            </div>
                        ))}
                </Carousel>
            </div>
            <div className="contact-container">
                <Link to="/contact">
                    <button>Contact us for viewing</button>
                </Link>
            </div>
            <div className="property-detail" style={{
                whiteSpace: 'pre-wrap',
                wordWrap: 'break-word',
                wordSpacing: "3px"}}
            dangerouslySetInnerHTML={{__html:singleProperty.description && singleProperty.description }}
            />

        </>
    );
}
export default PropertyDetail;