const WorkDetail = [
    {
        id:'1',
        number:'01',
        heading:"BUY",
        // info:"First you go through our website to find your perfect house."
    },
    {
        id:'2',
        number:'02',
        heading:"RENT",
        // info:"Once you find an appartment that fits your expectations, you can plan a viewing with us."
    },
    {
        id:'3',
        number:'03',
        heading:"SELL",
        // info:"When you fall in love with the appartment and you are satisfied with what you see, we meet again to officially close the deal."
    },
    {
        id:'4',
        number:'04',
        heading:"NEW DEVELOPMENT",
        // info:"Now that all papers are signed, you can call yourself the proud owner of your property."
    },
]
export default WorkDetail;