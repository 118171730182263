import React from "react";
import "../styles/pages/Properties.css";
import PropertyList from "../properties/PropertyList";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Properties(){

    return (
        <div>
            <Navbar />
            <PropertyList />
            <Footer />
        </div>
    )
}
export default Properties


