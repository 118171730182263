import { useState } from 'react'

// styles
import "../styles/auth/Login.css";
import {ToastTopCenter} from "../helper/ToastContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import OAuth from "./OAuth";
import {useLogin} from "../hooks/useLogin";
import {click} from "@testing-library/user-event/dist/click";

export default function Login(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { login, error, isLoading } = useLogin()

    const handleSubmit = (e) => {
        e.preventDefault()
        login(email, password)
    }

    const handleCloseBtn = () => {
        props.history.push("/")
    }
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            login(email, password)
        }
    };

    return (
        <form onSubmit={handleSubmit} className="login-container">
            {ToastTopCenter}
            <div className="login-form">
                <button
                    style={{cursor:"pointer"}}
                    className="close-btn"
                    onClick={handleCloseBtn}
                >Go back</button>

                <h1>Admin Login</h1>
                <div className="input-container">

                    <label>
                        <span>Email</span>
                        <input
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                    </label>
                </div>
                <div className="input-container">
                    <label>
                        <span>Password</span>
                    <input
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    </label>
                    <span
                        onClick={() => {
                            setShowPassword(prevState => !prevState)}}
                        style={{cursor:"pointer"}}
                    >
                        Show Password <FontAwesomeIcon icon={faEye}/>
                    </span>

                </div>

                <div className="input-container">
                    <Link to="/forgot-password">Forgot Password</Link>
                </div>
                <OAuth />

                { !isLoading && <button
                    className="login-btn">
                    Login
                </button>}
                { isLoading && <button className="btn" disabled>loading</button> }
                { error && <p className='text-red'>{error}</p> }
            </div>
        </form>
    )
}