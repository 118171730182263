import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PropertyDetail from "../properties/PropertyDetail";


function SinglePropertyPage(){

    return (
        <div>
            <Navbar />
            <PropertyDetail />
            <Footer />


        </div>
    )
}
export default SinglePropertyPage
