import React, {useState} from 'react'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import SqftImage from "../assets/icons/sqft-img.png";
import BedroomImage from "../assets/icons/icons8-sleeping-in-bed-100.png";
import BathroomImage from "../assets/icons/icons8-shower-80.png";
import Dialog from "../components/Dialog";
import {useHistory} from "react-router-dom";


const Card = ({data, deleteData}) => {
    const [showDialog, setDialog] = useState(false)
    const history = useHistory();

    return <div key={data.id}>
        {showDialog && <Dialog onDialog={setDialog} onYes={() => deleteData(data.id)} message="Are you sure you want to delete this property?"/>}
        <div className="action-header">
            <div className="action-title">{data.title}</div>
            <div className="change-icons">
                <FontAwesomeIcon
                    icon={faTrash}
                    onClick={()=>setDialog(true)}
                    className="action-icon"
                    style={{color:"#D22B2B"}}
                />

                <FontAwesomeIcon
                    className="action-icon"
                    style={{color: "#9b8742"}}
                    icon={faEdit} onClick={()=>history.push(`/edit-listing/${data.id}`)} />

            </div>

        </div>
        <div className="column" key={data.id}>
            <div className="single-property">
                <div className="card" id="card-id">
                    <div className="property-thumb">
                        <div className="property-tag capitalize">{data.listingType}</div>
                        <img
                            src={data.imgUrls ? data.imgUrls[0] : ''}
                            className="property-buy-image"
                            alt="Sint Maarten"
                        />
                        <span className="price"><b>{data.heading}</b></span>
                    </div>
                    <div className="property-content">
                        <h4><b>{data.price}</b></h4>

                        <div className="location-mark">
                            <span className="location">{data.address.substring(0,30)}</span>
                            <span className="location">{data.location}</span>
                        </div>
                    </div>

                    <div className="property-footer">
                        <ul>
                            <li className="card-footer-list">
                                <img
                                    className="icon-image"
                                    src={SqftImage}
                                    alt="size"
                                />
                                <span>{data.surface}</span>
                            </li>
                            <li>
                                <img
                                    className="icon-image"
                                    src={BedroomImage}
                                    alt="bed"
                                />
                                <span>{data.bedrooms} Beds</span>
                            </li>
                            <li>
                                <img
                                    className="icon-image"
                                    src={BathroomImage}
                                    alt="bath"
                                />
                                <span>{data.bath} Baths</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Card