import './App.css';
import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Properties from "./pages/Properties";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ForgotPassword from "./auth/ForgotPassword";
import AdminOverview from "./admin/AdminOverview";
import CreateListing from "./admin/CreateListing";
import SinglePropertyPage from "./pages/SinglePropertyPage";
import Login from "./auth/Login";



function App() {
  return (
      <BrowserRouter>
          <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/admin" component={AdminOverview} />
              <Route exact path="/create-listing" component={CreateListing} />
              <Route exact path="/edit-listing/:id" component={CreateListing} />
            <Route exact path="/" component={Home} />
            <Route exact path="/properties" component={Properties} />
            <Route exact path="/properties/:id" component={SinglePropertyPage} />
            <Route exact path="/about" component={About} />
            <Route exact path="/contact" component={Contact} />

        </Switch>
      </BrowserRouter>
  );
}

export default App;

