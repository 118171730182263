import React from "react";
import ImageSlider, { Slide } from "react-auto-image-slider";
import image1 from "../assets/images/image6.jpeg"
import image2 from "../assets/images/image9.png"
import image3 from "../assets/images/PHOTO-2023-03-08-18-14-01 (1).jpg"
import image4 from "../assets/images/PHOTO-2023-03-08-18-13-59.jpg"
import image5 from "../assets/images/PHOTO-2023-03-08-18-14-01.jpg"
import "../styles/home/HeaderSlider.css"

function HeaderSlider() {
    return (
        <div className="slider-img-container">
        <ImageSlider className="slider-container" effectDelay={500} autoPlayDelay={2000}>
            <Slide className='slider-img'>
                <img className='slider-img' alt="img1" src={image1} />
            </Slide>
            <Slide className='slider-img'>
                <img className='slider-img' alt="img2" src={image2} />
            </Slide>
            <Slide className='slider-img'>
                <img className='slider-img' alt="img3" src={image3} />
            </Slide>
            <Slide className='slider-img'>
                <img className='slider-img' alt="img4" src={image4} />
            </Slide>
            <Slide className='slider-img'>
                <img className='slider-img' alt="img4" src={image5} />
            </Slide>
        </ImageSlider>
        </div>
    );
}

export default HeaderSlider;