// Icons & styles
import "../styles/admin/AdminOverview.css"
import Card from "./Card";


export default function PropertyCardAdmin({properties, deleteData}) {

    return (
        <>
            {properties.length === 0 && <p>NO PROPERTIES</p>}
            <div className="grid md:grid-cols-2 lg:grid-cols-3">
            {properties.map(data =>  {
                    return <Card data={data} deleteData={deleteData} />
                })
            }
            </div>

        </>
    );
};