import React from "react";
import "../styles/home/OurVision.css";

const OurVision = () => {
    return (
        <section className="vision-section">
            <h1 className="vision-text">
                Welcome to AnRa Caribbean, your premier real estate company based on the beautiful island of St. Maarten.
                Our team of experienced professionals is dedicated to providing exceptional service, expert guidance, and personalized attention to help you achieve your real estate goals.
                Whether you are looking to buy, sell, rent a property or develop your own project in St. Maarten, we are here to assist you every step of the way.
                With a deep knowledge of the local market and a commitment to excellence, we strive to exceed your expectations and make your real estate experience a success.
                <br/><br/>

                At AnRa Caribbean, we specialize in real estate development and have a deep understanding of the local market. We work with developers, investors, and landowners to provide a comprehensive range of services, from feasibility studies and planning to design and construction management.

                <br/><br/>
                <b>Thank you for choosing AnRa Caribbean as your trusted partner in real estate and development. </b>
            </h1>
        </section>
    );
};

export default OurVision;