import React, {Fragment, useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import "../styles/components/Navbar.css";
import OutsideClick from "../helper/OutsideClick";
import { Link, NavLink } from "react-router-dom";
import Logo from "../assets/logo/5.png";


const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [transitionNavbar, setTransitionNavbar] = useState(false);

    const navRef = useRef(null);
    const isClickedOutside = OutsideClick(navRef);

    useEffect(() => {
        if (isClickedOutside){
            setShowMenu(false);
        }
    }, [isClickedOutside]);

    const changeBackground = () => {
        if (window.scrollY >= 150) {
            setTransitionNavbar(true);
        } else {
            setTransitionNavbar(false);
        }
    };
    window.addEventListener("scroll", changeBackground);

    return(
        <div>
            <Fragment>
                <nav
                    className={transitionNavbar ? "navbar nav-active" : "navbar"}
                    ref={navRef}
                >
                    <Link to="/">
                        <img className="logo" src={Logo} alt="Anra Caribbean Logo"/>
                        <span className="logo-slogan"> AnRa Caribbean Real Estate </span>
                    </Link>

                    <div className={showMenu ? "menu mobile-menu" : "menu"}>
                        <ul>
                            <li>
                                <NavLink exact to="/">
                                    Home
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/properties">
                                    Properties
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/about">
                                    About
                                </NavLink>
                            </li>
                            <li>
                                <NavLink exact to="/contact">
                                    Contact
                                </NavLink>
                            </li>
                        </ul>
                    </div>


                    <FontAwesomeIcon
                        className="fa-bars"
                        icon={faBars}
                        onClick={() => setShowMenu(!showMenu)}
                    />
                </nav>
            </Fragment>
        </div>
    )
}
export default Navbar;