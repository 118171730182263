import React from "react";
import "../styles/about/AboutDetail.css";
import Picture from "../assets/images/image14.jpeg";

const AboutDetail = () => {
    return (
        <div className="about-container">
            <h2>About Us</h2>
            <div className="image-container-style">
                <img className="sxm-pic" src={Picture} alt="view in St. Martin" />
            </div>

            <div className="text-container">
                <p>
                    Fadi Hasbani is the founder of AnRa Caribbean, a real estate company based on the beautiful island of St. Maarten.
                    Fadi has been a resident of St. Maarten for over 30 years and has a deep understanding of the local real estate market.
                    <br />
                    <br />
                    For over 25 years , Fadi was an active asset in developing the Harley-Davidson Motorcycles Brand with his partner and close friend Mr. Gil Guadalpi , the CEO  of Super Bikes NV ( 1986-2017) the Official distributor of Harley-Davidson brand in all the Caribbean islands.{" "}
                    <br />
                    <br />
                    Fadi's passion for real estate began after Hurricane Irma put an end to the Bikes and cars business, and with AnRa Caribbean he has since been committed to providing to his network of clients on St Maarten and abroad the same exceptional service he always provided since 1991.
                    <br />
                    <br />
                    With today’s fast growing social networks and digital markets’ business thinking, AnRa Caribbean kept the human, old fashion way of doing business as a core asset and business module because St Maarten is just a small village and our clients become our family. Time is different on St Maarten and business ethics too.What we mean by old fashion is that our clients are not File numbers, they are persons with whom we build a project from scratch and we make sure to see it coming through the fastest and best way possible.
                    <br />
                    <br />
                    Fadi's vision for AnRa Caribbean is to provide a unique and personalized approach to real estate, with a focus on exceptional service and client satisfaction.
                    He believes that every client is unique and that every real estate project requires a tailored approach.
                    <br />
                    <br />
                    Thank you for considering AnRa Caribbean and entrusting us with your real estate needs. We look forward to working with you to achieve your goals and to provide you with an exceptional real estate experience.
                    <br />
                    <br />
                    <i><b>AnRa Caribbean Real Estate team.<br/>
                        A Biker’s Legacy</b></i>
                </p>
            </div>
        </div>
    );
};

export default AboutDetail;