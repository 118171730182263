import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../firebase/config";
import { onAuthStateChanged } from "firebase/auth";
import Spinner from "../helper/Spinner";


const defaultState = { status: "loading", user: null };

export const AuthContext = createContext(defaultState);

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
    const [{ status, user }, setState] = useState(defaultState);
    useEffect(() => {
        const subscription = onAuthStateChanged(auth, user => setState({
            user
        }));
        return subscription;
    }, [])
    return status === "loading" ? <Spinner /> : <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
}