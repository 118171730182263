import { useEffect } from "react"
import { useHistory } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const useUnAuth = () => {
    const user = useAuth();
    const history = useHistory();
    useEffect(() => {
        if (user) history.replace('/admin')
    }, [user])
    return [user, history];
}

export default useUnAuth;