import React, { useState, useEffect, useCallback } from "react";
import { doc, onSnapshot, setDoc, updateDoc, where, collection, deleteDoc, getDocs,query } from "firebase/firestore";
import { getStorage, uploadBytes, ref, getDownloadURL } from 'firebase/storage'
import { db } from "../firebase/config";
import { useAuth } from "../context/AuthContext";

export const useCollection = () => {
    const [properties, setProperties] = useState([]);
    const [searchTerm,setSearchTerm] = useState('');
    const [filteredDataResult,setFilterDataResult] = useState([])
    const [error, setError] = useState(false);
    const user = useAuth();

    useEffect(() => {
        let ref = collection(db, "properties")
        const sub = onSnapshot(ref, (snp) => {
            const properties = snp.docs.map((doc) => {

                const data = doc.data();
                data.id = doc.id;
                data.images = [];
                return data

            })
            setProperties(properties)
        })
        return () => sub()

    }, [])

    const addData = async (data, id) => {
        console.log("🚀 ~ file: useCollection.js:24 ~ addData ~ data", data)
        data.userRef = user.uid;
        let ref = doc(db, "properties", id)
        const result = await setDoc(ref, data)
        console.log({result})
    }

    const updateData = async (id, data) => {
        let ref = doc(db, 'properties', id)
        await updateDoc(ref, data)
    }

    const deleteData = async (id) => {
        let ref = doc(db, 'properties', id)
        await deleteDoc(ref)
    }

    const uploadFiles = async (files) => {
        const uploadedFileUrl = await Promise.all(files.map(file => storedFile(file)))
        return uploadedFileUrl
    }

    const filterData = useCallback(async ({ type, val }) => {
        let data = !val ? getDocs(collection(db,'properties')) : getDocs(query(collection(db,'properties'),where(type,'==',val)))
        data.then(d=>{
            setProperties([])
            d.forEach(d=>{
                setProperties(p=>{
                    return[...p,{
                        ...d.data(),
                        images: d.data().images ?? d.data().imgUrls,
                        id: d.id
                    }]
                })
            })
        })

    }, [])

    const searchItem = (searchInput)=>{
        setSearchTerm(searchInput)
        if(searchInput !== ''){
            const filterData = properties.filter(item=>{
                return Object.keys(item).reduce((acc,cur)=>{
                    if(['heading','description','location','address', 'bath', 'bedrooms'].includes(cur)) acc+= item[cur]?.toLowerCase()
                    return acc
                },'').includes(searchInput.toLowerCase())
            })
            setFilterDataResult(filterData)
        }
    }

    const storedFile = (file) => {
        return new Promise((resolve, reject) => {
            const storage = getStorage()
            const storageRef = ref(storage, `images/${file.name}`)
            uploadBytes(storageRef, file).then((snapshot) => {
                getDownloadURL(snapshot.ref).then((downloadUrl) => {
                    resolve(downloadUrl)
                })
            }).catch(error => reject(error))
            setError(true)
            Error(error.message)


        })
    }
    return {
        properties,
        searchTerm,
        searchItem,
        filteredDataResult,
        uploadFiles,
        updateData,
        deleteData,
        addData
    }
}